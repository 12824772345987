body, html {
  font-family: sans-serif;
  margin: 0;
  background: fixed linear-gradient(to bottom, #dfe3ee 15%, #dfe3ee 85%);
}

#root {
  padding-bottom: 30px;
}

.navbar-default {
  background-color: #4060a5;
  border-bottom: solid 1px #8b9dc3;
  box-shadow: 0 0 20px rgba(0,0,0,0.7);
}

/* Title */
.navbar-default .navbar-brand {
  color: #ffffff;
}
.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
  color: #dfe3ee;
}

/* Link */
.navbar-default .navbar-nav > li > a {
  color: #ffffff;
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  color: #555;
  background-color: #ffffff;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: #555;
  background-color: #ffffff;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  color: #555;
  background-color: #ffffff;
}

.navbar-toggle .icon-bar{
  background-color: #ffffff !important;
}

.navbar-toggle .icon-bar:hover{
  background-color: #ffffff;
}

.navbar-default .navbar-toggle:hover {
  background-color: transparent;
}

img.banner {
  margin-top: 50px;
  margin-bottom: -50px;
  border-bottom:solid 1px #444444;
  box-shadow: 0 5px 20px rgba(0,0,0,0.7);
  width: 100%;
  height: 100%;
}

.page {
  margin-top: 50px;
  background-color: #FFFFFF;
  padding-bottom: 55px;
  border-right: solid 1px #777777;
  border-left: solid 1px #777777;
  border-bottom: solid 1px #777777;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 0 20px rgba(0,0,0,0.7);
}

.carouselcaption {
  background-color: #FFFFFF;
  color: #000000;
  border-radius: 10px;
  border: solid 1px #777777;
  opacity: 0.8;
  box-shadow: inset 0 0 10px #000000;
  text-shadow: none;
}

@media only screen and (max-width: 500px) {
  .carousel-caption {
    display:none;
  }

  .carousel-indicators {
    display:none;
  }
}

@media only screen and (max-width: 768px) {
  .carouselcaption {
    font-size:9px;
  }

  .carouselcaption h2 {
    font-size:16px;
    margin-top: 10px;
  }
}

/* Remove yellow autofill on chrome form fields */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset !important;
}

a:link {
  color:#444444;
}

a:hover {
  color:#000000;
}

a:visited {
  color:#888888;
}

.BlinkingIcon {
  /*animation: blinker 1.5s ease infinite;
  color: #d9534f;*/
}

@-webkit-keyframes blinker {
  60% {
    opacity: 0;
  }
}

@keyframes blinker {
  60% {
    opacity: 0;
  }
}

.LoaderButton .spinning.fa {
  margin-right: 7px;
  top: 2px;
  -webkit-animation: spin 0.7s infinite linear;
          animation: spin 0.7s infinite linear;
}
@-webkit-keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
@keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}

/* Tutorial Summary Styles */
.tutorial-summary {
  display: block;
  border: solid 1px #91a6d4;
  padding: 5px;
  border-radius:5px;
}

.tutorial-summary:hover {
  text-decoration: none;
  background-color: #f4faff;
  color: #000000;
}

/* Nest OL List Styles */

ol {
  counter-reset: item;
  margin-top: 15px;
}

ol > li {
  counter-increment: item;
}

ol ol > li {
  display: block;
  margin-bottom: 15px;
}

ol ol > li:before {
  content: counters(item, ".") " ";
  margin-left: -24px;
}

/* Footer Styles */
.footer {
  position: relative;
  background-color: #91a6d4;
  height:40px;
  margin-top:-40px;
  color:#FFFFFF;
  border: solid 1px #777777;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-top: 10px;
  font-weight: bold;
}

.footer a:link {
  color:#ffffff;
  text-decoration: none;
}

.footer a:visited {
  color:#ffffff;
}

.footer a:hover {
  color:#e2e2e2;
}

.btn {transition: 0.3s;}

.loginForm {margin-top:15px;border-left: 1px solid #e2e2e2;}
@media (max-width:767px){.loginForm {border-left: none;}}

/* Social Login Button Styles */
.btn-amazon, .btn-facebook, .btn-google {width: 250px; text-align:left;}
.btn-amazon .loginAmazonIcon, .btn-facebook .loginFacebookIcon, .btn-google .loginGoogleIcon {
  height:46px;
  float: left;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.btn-amazon .loginAmazonIcon .spinning.fa, .btn-facebook .loginFacebookIcon .spinning.fa {margin-right:0;}
.btn-google .loginGoogleIcon .spinning.fa {margin-right:0; color:#000000;}

.btn-amazon {
  background-color: #FCDA85;
  border: solid 1px #AA801F;
  color:#000000;
}
.btn-amazon:visited, .btn-amazon:hover, .btn-amazon:active, .btn-amazon:focus  {
  background-color: #F5C145;
  color:#000000;
}
.btn-amazon .loginAmazonIcon {
  color:#000000;
  padding:11px 11px 0 11px;
  border:1px solid #AA801F;
  margin:-11px 10px -11px -17px;
  background-color:#FCDA85;
}

.btn-facebook {
  background-color: #4267b2;
  border: solid 1px #29487d;
  color:#FFFFFF;
}
.btn-facebook:visited, .btn-facebook:hover, .btn-facebook:active, .btn-facebook:focus  {
  background-color: #365899;
  color:#FFFFFF;
}
.btn-facebook .loginFacebookIcon {
  color:#FFFFFF;
  padding:11px 11px 0 11px;
  border:1px solid #29487d;
  margin:-11px 10px -11px -17px;
  background-color:#4267b2;
}

img.googleSignIn {width:23px;}
.btn-google {
  background-color: #4285F4;
  border: solid 1px #29487d;
  color:#FFFFFF;
}
.btn-google:visited, .btn-google:hover, .btn-google:active, .btn-google:focus  {
  background-color: #4078d9;
  color:#FFFFFF;
}
.btn-google .loginGoogleIcon {
  color:#FFFFFF;
  padding:9px 10px 0 12px;
  border:1px solid #000000;
  margin:-11px 10px -11px -17px;
  background-color:#FFFFFF;
}
/* Social Login Button Styles */


/* Social Share Styles */
.react-share__ShareButton {
  display:inline-block;
  margin-right:5px;
}

.btn-linkedIn {
  background-color: #007bb5;
  border: solid 1px #125688;
  color:#FFFFFF;
  padding: 2px 5px;
}

.btn-linkedIn:visited, .btn-linkedIn:hover, .btn-linkedIn:active, .btn-linkedIn:focus {
  background-color: #0460a6;
  color:#FFFFFF;
}


.btn-twitter {
  background-color: #55ACEE;
  border: solid 1px #007bb5;
  color:#FFFFFF;
  padding: 2px 5px;
}

.btn-twitter:visited, .btn-twitter:hover, .btn-twitter:active, .btn-twitter:focus  {
  background-color: #478ec4;
  color:#FFFFFF;
}


/* Social Share Styles */

/* Billing Form Styles */
.BillingForm .card-field {
  margin-bottom: 15px;
  background-color: white;
  padding: 11px 16px;
  border-radius: 6px;
  border: 1px solid #CCC;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  line-height: 1.3333333;
}

.BillingForm .card-field.StripeElement--focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
  border-color: #66AFE9;
}


.blog {
  display: block;
  border: solid 1px #91a6d4;
  padding: 10px;
  margin-top: -1px;
}

.blog h4 {margin-top:0;margin-bottom:0}
.posted {font-size:9px;}

.blog img {
  float:left;
  margin-right:10px;
  width:25vw;
  border:solid 1px #000000;
}
.blog:hover {
  text-decoration: none;
  background-color: #f4faff;
  color: #000000;
  cursor: pointer;
}

.newblog {
  display: block;
  border: solid 1px #91a6d4;
  padding: 10px;
  margin-top: -1px;
}
.newblog h4 {margin-top:0;margin-bottom:0}
.newblog img {
  float:left;
  margin-right:10px;
  width:25vw;
  border:solid 1px #000000;
}
.newblog img:hover {
  opacity: 0.6;
  box-shadow: 0 0 10px #000000;
}

.newblog:hover {
  text-decoration: none;
  background-color: #f4faff;
  color: #000000;
}

.fb-comments iframe{width:100%!important}

.fb-page,
.fb-page span,
.fb-page span iframe[style] {
  width: 100% !important;
}

h1#blogPost {
  margin-bottom: 0;
  font-size:2.5vw;
}

@media(max-width:767px) {
  .blog > img {width: 40vw;}
  .sidebarAdTop {margin-top:20px}
  .sidebarAdTopAndBottom {margin-top:20px; margin-bottom:20px;}
  .sidebarAdBottom {margin-bottom:20px;}
}

/* Modal Styles */
.modal-header-success {
  color:#FFFFFF;
  font-size: 30px;
  padding:9px 15px;
  border-bottom:1px solid #000000;
  background-color: #5cb85c;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.modal-header-warning {
  color:#FFFFFF;
  font-size: 30px;
  padding:9px 15px;
  border-bottom:1px solid #000000;
  background-color: #f0ad4e;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.modal-header-danger {
  color:#FFFFFF;
  font-size: 30px;
  padding:9px 15px;
  border-bottom:1px solid #000000;
  background-color: #d9534f;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.modal-header-info {
  color:#FFFFFF;
  font-size: 30px;
  padding:9px 15px;
  border-bottom:1px solid #000000;
  background-color: #5bc0de;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.modal-header-primary {
  color:#FFFFFF;
  font-size: 30px;
  padding:9px 15px;
  border-bottom:1px solid #000000;
  background-color: #428bca;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

/* Code Snippet Styles */
pre {
  border: none;
  padding: 0;
  margin: 0;
}

.public-DraftStyleDefault-ol, .public-DraftStyleDefault-ul {
  margin-top:0 !important;
  margin-bottom:0 !important;
}

.public-DraftStyleDefault-pre {
  padding: 15px 20px;
}

/* Text Editor Toolbar Styles */
.draftJsToolbar__toolbar__dNtBH {
  margin-bottom: 15px;
  background: #fbfbfb !important;
}

.headlineButtonWrapper {
  display: inline-block;
}

.headlineButton {
  background: #fbfbfb;
  color: #888;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
}

.headlineButton:hover,
.headlineButton:focus {
  background: #f3f3f3;
}

/* Bootstrap Popover Styles */
.popover {
  max-width: 700px;
}

/* Text Editor Link Formatting Styles */
.draftJsLinkifyPlugin__link__2ittM:link {
  color: #5e93c5;
}

/* set the image in the Draft.js editor to 100% so it's responsive */
.public-DraftEditor-content img {width: 100%; border:solid 1px #000000;}
.draftJsMentionPlugin__link__TQHAX {color:#2996da !important; font-weight:bold;}

/* checkout border */
.checkoutBorder {
  margin-top: 15px;
  padding-top: 10px;
  border-right: solid 1px #e2e2e2;
}

/* Overrides for XS devices */
@media(max-width:767px){
  .footer {
    border-right: hidden;
    border-left: hidden;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .page {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-right: hidden;
    border-left: hidden;
  }
  .checkoutBorder {
    border: hidden;
  }
}
